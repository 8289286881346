import { computed, SetupContext } from '@vue/composition-api';
import { useState } from '.';
import { watchRun } from '../vue';
import { useBreakpoints } from './utils/useBreakpoints';
import { useRoute } from './utils/useRouter';
import { useVuetify } from './utils/useVuetify';

export function useContextSidebar(ctx: SetupContext) {
  const width = 300;

  const miniWidth = 40;

  const $temporarySidebarOpen = useState(s => s.layout.temporarySidebar.open);

  const { $showMiniSidebar, $width: $currentWidth } = useBreakpoints();

  const $miniVariant = computed(() =>
    $showMiniSidebar.value ? $mini.value : false,
  );

  const $mini = useState(s => s.layout.contextSidebar.mini);

  return {
    $currentWidth,
    width,
    miniWidth,
    $temporarySidebarOpen,
    $showMiniSidebar,
    $miniVariant,
    $mini,
  };
}

export function useUpdateAppLeft(ctx: SetupContext) {
  const {
    $miniVariant,
    miniWidth,
    width,
    $temporarySidebarOpen,
    $currentWidth,
  } = useContextSidebar(ctx);

  function checkNoSidebar() {
    const fullPath = useRoute().fullPath;

    const routesNoSidebar = [
      '/pacientes',
      '/configuracoes/meu-perfil',
      '/contatos',
      '/cid',
    ];

    return (
      routesNoSidebar.includes(fullPath) ||
      // routes => /cid/*
      fullPath.includes('/cid/') ||
      // routes => /contatos/*
      fullPath.includes('/contatos/') ||
      // routes => /erro/*
      fullPath.includes('/erro/')
    );
  }

  function updateAppLeft() {
    const vuetify = useVuetify();

    if (checkNoSidebar()) {
      return (vuetify.application.left = 0);
    }

    // fix the left padding issue
    vuetify.application.left = $miniVariant.value ? miniWidth : width;
  }

  watchRun($temporarySidebarOpen, updateAppLeft);

  watchRun($currentWidth, updateAppLeft);

  watchRun(() => useRoute().fullPath, updateAppLeft);
}
