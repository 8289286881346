import { useContextSidebar } from '@/lib/composables/useContextSidebar';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent, modifiers } from '@/lib/vue';
import { LayoutState } from '@/store/modules/layout.store';
import { computed } from '@vue/composition-api';

export const ContextSidebar = createComponent({
  name: 'ContextSidebar',
  setup(props, ctx) {
    const { $mini, width, miniWidth, $miniVariant, $showMiniSidebar } =
      useContextSidebar(ctx);

    const $miniIcon = computed(() =>
      $mini.value ? MyIcons.sidebarExpand : MyIcons.sidebarCollapse,
    );

    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <div id="ContextSidebar">
          <v-navigation-drawer
            app
            fixed
            stateless
            clipped
            disableResizeWatcher
            value
            width={width}
            miniVariantWidth={miniWidth}
            miniVariant={$miniVariant.value}
          >
            {$showMiniSidebar.value && (
              <v-btn
                id="toggle-sidebar"
                icon
                absolute
                ripple={false}
                color={MyTheme.coolGray800}
                onClick={modifiers.stop(LayoutState.toggleContext)}
              >
                <v-icon>{$miniIcon.value}</v-icon>
              </v-btn>
            )}

            {!$miniVariant.value && defaultSlot}
          </v-navigation-drawer>
        </div>
      );
    };
  },
});

export default ContextSidebar;
