import { createComponent } from '@/lib/vue';

interface IProps {
  title: string;
}

export const ContextSidebarMenuHeader = createComponent<IProps>({
  name: 'ContextSidebarMenuHeader',
  props: {
    title: { type: String, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div id="ContextSidebarMenuHeader">
        <v-subheader class="my-2 font-normal text-coolGray-700 text-title">
          {props.title}
        </v-subheader>

        <v-divider />
      </div>
    );
  },
});

export default ContextSidebarMenuHeader;
