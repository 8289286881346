import { CircularLoading } from '@/components/loading/CircularLoading';
import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenuHeader } from '@/components/sidebars/context/ContextSidebarMenuHeader';
import { useState, useValue } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { formatTelefone } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { AgendamentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { AgendamentoStatus, IAgendamentoFragment } from '@/typings';
import { onMounted } from '@vue/composition-api';

export default createComponent({
  name: 'PacientesDiaSidebar',
  setup(props, ctx) {
    const { $loading } = useLoadAgendamentos();

    const $agendamentos = useState(s => s.agenda.agendamentosDia);

    return () => (
      <ContextSidebar>
        <ContextSidebarMenuHeader title="Pacientes do dia" class="pt-2" />

        <CircularLoading loading={$loading.value} size={50}>
          <v-list class="py-0">
            {$agendamentos.value.map((v, index) => [
              index > 0 && <v-divider />,

              <v-menu
                class="flex"
                key={v.id}
                openOnHover
                maxWidth="380"
                closeOnContentClick={false}
                offsetX
                nudgeLeft={10}
                nudgeBottom={10}
                closeDelay="50"
                scopedSlots={{
                  activator: menuActivator(v),
                }}
              >
                <PacienteSheet item={v} />
              </v-menu>,
            ])}
          </v-list>
        </CircularLoading>
      </ContextSidebar>
    );
  },
});

function useLoadAgendamentos() {
  const [$loading, setLoading] = useValue(true);

  onMounted(async () => {
    await AgendamentoService.loadAgendamentosDia();

    setLoading(false);
  });

  return { $loading };
}

const getStatus = (status: AgendamentoStatus | null) =>
  ConstantsHelper.agendamentoStatus.find(f => f.value === status);

const menuActivator =
  (agendamento: IAgendamentoFragment) =>
  ({ on }) => {
    const status = getStatus(agendamento.status)!;

    const horaInicial = DateHelpers.formatHour(agendamento.horaInicial);

    return (
      <v-list-item
        ripple
        exact
        {...{
          on: {
            ...on,
            click: () => AgendamentoService.goToAtendimento(agendamento),
          },
        }}
      >
        <v-list-item-action>
          <v-icon color={status.color}>{status.icon}</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            <div class="flex items-center space-x-3">
              <div
                class="font-medium text-body"
                style={{ color: status.color }}
              >
                {horaInicial}
              </div>

              {agendamento.primeiraVez && (
                <div class="pt-0">
                  <v-chip
                    id="chip"
                    color="success"
                    text-color={MyTheme.white}
                    small
                  >
                    PRIMEIRA VEZ
                  </v-chip>
                </div>
              )}
            </div>
          </v-list-item-title>

          <div class="mt-2 text-body">{agendamento.paciente?.nome}</div>
        </v-list-item-content>
      </v-list-item>
    );
  };

const PacienteSheet = createComponent<{
  item: IAgendamentoFragment;
}>({
  name: 'PacientesDiaSidebarPacienteSheet',
  props: {
    item: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const { status, telefone, convenio, procedimento, createdBy, createdAt } =
        mapPacienteDia(props.item);

      return (
        <v-sheet>
          <div class="flex flex-col p-4">
            <div class="flex flex-col p-0 text-body-sm">
              <div class="flex items-center">
                <span>Status:</span>

                <AgendamentoStatusInfo value={status} />
              </div>

              <div>Telefone: {telefone}</div>

              <div>Convênio: {convenio}</div>

              <div>Procedimento: {procedimento}</div>
            </div>

            <v-btn
              color="primary"
              class="my-2"
              block
              onClick={() => AgendamentoService.goToAtendimento(props.item)}
            >
              atender
            </v-btn>

            <div class="text-center text-gray-600 text-small">
              * Agendado por {createdBy} em {createdAt}
            </div>
          </div>
        </v-sheet>
      );
    };
  },
});

const AgendamentoStatusInfo = createComponent<{
  value: AgendamentoStatus | null;
}>({
  name: 'PacientesDiaSidebarAgendamentoStatusInfo',
  props: {
    value: { type: String as any },
  },
  setup(props, ctx) {
    return () => {
      if (!props.value) {
        return null;
      }

      const status = getStatus(props.value);

      return (
        status && (
          <div style={{ color: status.color }} class="flex items-center">
            <v-icon size={20} color={status.color} class="mx-1">
              {status.icon}
            </v-icon>

            {status.label}
          </div>
        )
      );
    };
  },
});

interface IPacienteDia {
  status: AgendamentoStatus | null;
  telefone: string | null | undefined;
  convenio: string | null | undefined;
  procedimento: string;
  createdAt: string | null | undefined;
  createdBy: string | null | undefined;
}

function mapPacienteDia(agendamento: IAgendamentoFragment): IPacienteDia {
  const paciente = agendamento.paciente!;

  const celular = formatTelefone(paciente.celular);
  const telefoneCasa = formatTelefone(paciente.telefoneCasa);

  return {
    status: agendamento.status,
    telefone:
      celular && telefoneCasa
        ? `${celular} / ${telefoneCasa}`
        : celular || telefoneCasa,
    convenio: agendamento.convenio?.nome,
    procedimento: (agendamento.procedimentos || [])
      .reduce(
        (res, procedimento) => `${res} ${procedimento.procedimento.nome},`,
        '',
      )
      // remove last character
      .slice(0, -1)
      .trim(),
    createdBy: agendamento.createdBy?.nome,
    createdAt: DateHelpers.formatDateAndHour(agendamento.createdAt),
  };
}
